<template>
  <div class="container">
    <h1>
      내가
      <span class="large">라면</span>이
      <span class="large">라면</span>?
    </h1>
    <img src="/quiz/오프닝.png" alt="'내가 라면이라면?'이라고 적혀있는 라면 봉지">
    <h2>'라면으로 보는 나는?'</h2>
    <button class="cta" @click="startTest">테스트 시작하기</button>
  </div>
</template>
<script>
import firebase from "firebase/app";
import "firebase/firestore";
export default {
  name: "Home",
  methods: {
    startTest() {
      const increment = firebase.firestore.FieldValue.increment(1);
      const docRef = this.$firestore.collection('user').doc('rbti');
      docRef.update({ startCount: increment });
      this.$router.push({
        name: "Test"
      })
    }
  },
};
</script>
<style lang="scss" scoped>
</style>
